<template>
    <div class="goodsManage-wrapper">
        <div class="goodsMange-top">
            <div class="headline">添加商品</div>
        </div>
        <el-form :model="addForm" ref="addForm" :rules="addRules" label-position="top" class="addForm-container">
            <el-scrollbar class="addForm-wrapper">
                <el-form-item label="商品标题" prop="goods_name">
                    <el-input v-model="addForm.goods_name" type="text" minlength="3" maxlength="50" show-word-limit autocomplete="off" placeholder="3-50字" style="width: 340px"></el-input>
                </el-form-item>
                <el-form-item prop="goods_cover">
                    <template slot="label">
                        商品封面<span class="text-prompt">备注：尺寸800px X 800px</span>
                    </template>
                    <div class="goodCover-wrapper">
                        <div class="upload-cover">
                            <input type="file" accept=".jpg, .jpeg, .png" class="file-input" @change="changeFile">
                            <i class="el-icon-plus iconfont"></i>
                            <span class="file-text">上传封面</span>
                        </div>
                        <div class="cover-wrapper" v-if="addForm.goods_cover">
                            <img :src="addForm.goods_cover" alt="">
                            <i class="el-icon-close iconfont" @click="clearImg"></i>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="商品价格" prop="goods_price">
<!--                    <el-input-number v-if="addForm.goods_commission" v-model="addForm.goods_price" :min="addForm.goods_commission * 2" :precision="2" :controls="false" placeholder="请输入数字" style="width: 340px"></el-input-number>-->
                    <el-input-number v-model="addForm.goods_price" :min="0.01" :precision="2" :controls="false" placeholder="请输入数字" style="width: 340px"></el-input-number>
                </el-form-item>
<!--                <el-form-item label="商品佣金" prop="goods_commission">-->
<!--                    <el-input-number v-model="addForm.goods_commission" :min="0.01" :max="addForm.goods_price * 0.5" :precision="2" :controls="false" placeholder="输入佣金值不超过商品价格的50%" style="width: 340px"></el-input-number>-->
<!--                </el-form-item>-->
                <el-form-item label="商品佣金" prop="goods_commission_percent">
                    <el-input-number v-model="addForm.goods_commission_percent" :min="1" :max="50" :precision="0" :controls="false" placeholder="输入佣金比例最高为50%" style="width: 340px"></el-input-number>
                    <span style="display: inline-block; width: 20px; text-align: right">%</span>
                </el-form-item>
                <el-form-item label="优惠券链接" prop="coupon_link">
                    <el-input v-model="addForm.coupon_link" autocomplete="off" style="width: 340px" placeholder="请输入正确的优惠券链接，以'http://'或'https://'开头"></el-input>
                </el-form-item>
                <el-form-item label="下单链接" prop="order_link">
                    <el-input v-model="addForm.order_link" autocomplete="off" style="width: 340px" placeholder="请输入正确的下单链接，以'http://''或'https://'开头"></el-input>
                </el-form-item>
            </el-scrollbar>
        </el-form>
        <div class="form-footer" style="text-align: center">
            <el-button size="medium" class="a-blue-btn" @click="addToForm('addForm')">保存</el-button>
            <el-button size="medium" v-if="!addForm.id" @click="resetForm('addForm')">重置</el-button>
            <el-button size="medium" @click="backBtn">返回</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                addForm: {
                    id: Number(this.$route.query.id) || '',
                    goods_name: '',
                    goods_cover: '',
                    goods_price: void 0,
                    // goods_commission: void 0,
                    goods_commission_percent: void 0,
                    coupon_link: '',
                    order_link: '',
                },
                addRules: {
                    goods_name: [
                        { required: true, message: '请输入商品标题', trigger: 'blur' },
                        { min: 3, max: 50, message: '长度在 3 到 50 个字', trigger: 'blur' }
                    ],
                    goods_cover: [
                        { required: true, message: '请选择商品图片', trigger: 'blur'},
                    ],
                    goods_price: [
                        { required: true, message: '商品价格不能为空', trigger: 'blur'},
                    ],
                    // goods_commission: [
                    //     { required: true, message: '输入佣金值不超过商品价格的50%', trigger: 'blur'},
                    // ],
                    goods_commission_percent: [
                        { required: true, message: '输入佣金比例最高为50%', trigger: 'blur'},
                    ],
                    coupon_link: [
                        { required: true, message: '请输入优惠券链接', trigger: 'blur' }
                    ],
                    order_link: [
                        { required: true, message: '请输入下单链接', trigger: 'blur' }
                    ],
                },
            }
        },
        mounted() {
            if (this.addForm.id) {
                this.getGoodInfo()
            }
        },
        methods: {
            // 获取商品信息
            getGoodInfo() {
                this.$http.axiosGetBy(this.$api.adminGoods_get, {id: this.addForm.id}, (res) => {
                    if (res.code === 200) {
                        this.addForm = res.data
                        this.addForm.goods_cover = res.data.goods_cover.url
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 上传封面
            changeFile(event) {
                let file = event.target.files[0]
                if (file.size / 1024 / 1024 > 2) {
                    return this.$message.warning('上传商品封面大小不能超过 2MB!')
                }
                let width = 0
                let height = 0
                let reader = new FileReader()
                reader.onload = (e) => {
                    let data = e.target.result
                    //加载图片获取图片真实宽度和高度
                    let image = new Image()
                    image.onload = () => {
                        width = image.width
                        height = image.height
                        if (width !== 800 || height !== 800) {
                            return this.$message.warning('商品封面尺寸为800px * 800px')
                        }
                        let formData = new FormData()
                        formData.append('file', file)
                        this.$http.axiosPost(this.$api.adminGoods_uploadGoodsCoverImg, formData,(res) => {
                            if (res.code === 200) {
                                this.$message.success(res.message)
                                this.addForm.goods_cover = res.data.url // 没有域名
                            } else {
                                this.$message.warning(res.msg)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    }
                    image.src = data
                }
                reader.readAsDataURL(file)
                event.target.value = ''
            },
            // 清除图片
            clearImg() {
                this.addForm.goods_cover = ''
            },
            // 添加表单确定
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // if (Number(this.addForm.goods_commission) > Number(this.addForm.goods_price) * 0.5) {
                        //     return this.$message.warning('输入佣金值不超过商品价格的50%')
                        // }
                        if (!this.addForm.coupon_link.startsWith('http://') && !this.addForm.coupon_link.startsWith('https://')) {
                            return this.$message.warning('请输入正确的优惠券链接')
                        }
                        if (!this.addForm.order_link.startsWith('http://') && !this.addForm.order_link.startsWith('https://')) {
                            return this.$message.warning('请输入正确的下单链接')
                        }
                        let formData = new FormData();
                        formData.append('goods_name', this.addForm.goods_name);
                        formData.append('goods_cover', this.addForm.goods_cover);
                        formData.append('goods_price', this.addForm.goods_price);
                        // formData.append('goods_commission', this.addForm.goods_commission);
                        formData.append('goods_commission_percent', this.addForm.goods_commission_percent);
                        formData.append('coupon_link', this.addForm.coupon_link);
                        formData.append('order_link', this.addForm.order_link);
                        if (this.addForm.id) {
                            formData.append('id', this.addForm.id);
                        }
                        this.$http.axiosPost(this.$api.adminGoods_save, formData, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.message)
                                this.$router.push('/admin/goodsManage/list')
                            } else {
                                this.$message.warning(res.message)
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 重置表单
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // 返回
            backBtn() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .goodsManage-wrapper {
        display: flex;
        flex-direction: column;
    }
    .goodsMange-top {
        padding: 20px 40px 0;
        display: flex;
        align-items: center;
        .headline {
            line-height: 40px;
            font-size: 16px;
        }
    }
    .addForm-container {
        padding-left: 40px;
        height: 1%;
        flex: 1;
        border-top: 1px solid #F6F5F8;
        .addForm-wrapper {
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        ::v-deep &.el-form--label-top {
            .el-form-item__label {
                padding: 0;
            }
        }
        .text-prompt {
            font-size: 12px;
            color: #FF0000;
            margin-left: 25px;
        }
    }
    ::v-deep .el-input-number {
        .el-input__inner {
            text-align: left;
        }
        /*&.isError {*/
        /*    .el-input__inner {*/
        /*        border-color: #FD4446;*/
        /*    }*/
        /*}*/
    }
    .form-footer {
        margin: 20px 0;
        text-align: center;
    }
    .goodCover-wrapper {
        display: flex;
        .upload-cover {
            width: 200px;
            height: 200px;
            background: #F1F2FF;
            border-radius: 2px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #1E33E4;
            .file-input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                outline: none;
                opacity: 0;
                cursor: pointer;
            }
            .iconfont {
                font-size: 22px;
            }
            .file-text {

            }
        }
        .cover-wrapper {
            width: 200px;
            height: 200px;
            margin-left: 20px;
            background: #F1F2FF;
            display: flex;
            position: relative;
            img {
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
            .iconfont {
                position: absolute;
                right: -20px;
                cursor: pointer;
                font-size: 20px;
                &:hover {
                    color: #ff0000;
                }
            }
        }
    }
</style>